import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const Minimal = Loadable(lazy(() => import('../views/dashboards/Minimal')));
const Analytical = Loadable(lazy(() => import('../views/dashboards/Analytical')));
const Demographical = Loadable(lazy(() => import('../views/dashboards/Demographical')));
const Modern = Loadable(lazy(() => import('../views/dashboards/Modern')));

/***** Apps ****/
const Notes = Loadable(lazy(() => import('../views/apps/notes/Notes')));
const Chat = Loadable(lazy(() => import('../views/apps/chat/Chat')));
const Contacts = Loadable(lazy(() => import('../views/apps/contacts/Contacts')));
const Calendar = Loadable(lazy(() => import('../views/apps/calendar/CalendarApp')));
const Email = Loadable(lazy(() => import('../views/apps/email/Email')));
const Shop = Loadable(lazy(() => import('../views/apps/ecommerce/Shop')));
const ShopDetail = Loadable(lazy(() => import('../views/apps/ecommerce/ShopDetail')));
const Treeview = Loadable(lazy(() => import('../views/apps/treeview/TreeView')));
const TicketList = Loadable(lazy(() => import('../views/apps/ticket/TicketList')));
const TicketDetail = Loadable(lazy(() => import('../views/apps/ticket/TicketDetail')));

/***** Ui Elements ****/
const Alerts = Loadable(lazy(() => import('../views/ui/Alerts')));
const Badges = Loadable(lazy(() => import('../views/ui/Badges')));
const Buttons = Loadable(lazy(() => import('../views/ui/Buttons')));
const Cards = Loadable(lazy(() => import('../views/ui/Cards')));
const Grid = Loadable(lazy(() => import('../views/ui/Grid')));
const Tables = Loadable(lazy(() => import('../views/ui/Tables')));
const Forms = Loadable(lazy(() => import('../views/ui/Forms')));
const Breadcrumbs = Loadable(lazy(() => import('../views/ui/Breadcrumbs')));
const Dropdowns = Loadable(lazy(() => import('../views/ui/DropDown')));
const BtnGroup = Loadable(lazy(() => import('../views/ui/BtnGroup')));
const Collapse = Loadable(lazy(() => import('../views/ui/Collapse')));
const ListGroup = Loadable(lazy(() => import('../views/ui/ListGroup')));
const Modal = Loadable(lazy(() => import('../views/ui/Modal')));
const Navbar = Loadable(lazy(() => import('../views/ui/Navbar')));
const Nav = Loadable(lazy(() => import('../views/ui/Nav')));
const Pagination = Loadable(lazy(() => import('../views/ui/Pagination')));
const Popover = Loadable(lazy(() => import('../views/ui/Popover')));
const Progress = Loadable(lazy(() => import('../views/ui/Progress')));
const Spinner = Loadable(lazy(() => import('../views/ui/Spinner')));
const Tabs = Loadable(lazy(() => import('../views/ui/Tabs')));
const Toasts = Loadable(lazy(() => import('../views/ui/Toasts')));
const Tooltip = Loadable(lazy(() => import('../views/ui/Tooltip')));

/***** Form Layout Pages ****/
const FormBasic = Loadable(lazy(() => import('../views/form-layouts/FormBasic')));
const FormGrid = Loadable(lazy(() => import('../views/form-layouts/FormGrid')));
const FormGroup = Loadable(lazy(() => import('../views/form-layouts/FormGroup')));
const FormInput = Loadable(lazy(() => import('../views/form-layouts/FormInput')));

/***** Form Pickers Pages ****/
const Datepicker = Loadable(lazy(() => import('../views/form-pickers/DateTimePicker')));
const TagSelect = Loadable(lazy(() => import('../views/form-pickers/TagSelect')));

/***** Form Validation Pages ****/
const FormValidate = Loadable(lazy(() => import('../views/form-validation/FormValidation')));
const FormSteps = Loadable(lazy(() => import('../views/form-steps/Steps')));

const FormEditor = Loadable(lazy(() => import('../views/form-editor/FormEditor')));
/***** Table Pages ****/
const Basictable = Loadable(lazy(() => import('../views/tables/TableBasic')));
const CustomReactTable = Loadable(lazy(() => import('../views/tables/CustomReactTable')));
const ReactBootstrapTable = Loadable(lazy(() => import('../views/tables/ReactBootstrapTable')));

/***** Chart Pages ****/
const ApexCharts = Loadable(lazy(() => import('../views/charts/ApexCharts')));
const ChartJs = Loadable(lazy(() => import('../views/charts/ChartJs')));

/***** Sample Pages ****/
const StarterKit = Loadable(lazy(() => import('../views/sample-pages/StarterKit')));
const Profile = Loadable(lazy(() => import('../views/sample-pages/Profile')));
const Gallery = Loadable(lazy(() => import('../views/sample-pages/Gallery')));
const SearchResult = Loadable(lazy(() => import('../views/sample-pages/SearchResult')));
const HelperClass = Loadable(lazy(() => import('../views/sample-pages/HelperClass')));

/***** Icon Pages ****/
const Bootstrap = Loadable(lazy(() => import('../views/icons/Bootstrap')));
const Feather = Loadable(lazy(() => import('../views/icons/Feather')));

/***** Map Pages ****/
const CustomVectorMap = Loadable(lazy(() => import('../views/maps/CustomVectorMap')));

/***** Widget Pages ****/
const Widget = Loadable(lazy(() => import('../views/widget/Widget')));

/***** CASL Access Control ****/
const CASL = Loadable(lazy(() => import('../views/apps/accessControlCASL/AccessControl')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

// -------------------------------------------------------------------------------------------------------
// <========================================> UNIVERSITY ROUTES START <=========================================>
// -------------------------------------------------------------------------------------------------------

// *********application form steps************
const FormStepsMain = Loadable(lazy(() => import('../pages/applicationSteps/StepsMain.tsx')));
// *********apllicatnt profile details************
const ApplicantProfile = Loadable(
  lazy(() => import('../pages/applicantProfile/ApplicantProfile.tsx')),
);

const ApplicantProgramApplied = Loadable(
  lazy(() => import('../pages/applicantProgram/ApplicantProgramApplied.tsx')),
);
const ApplicantProgramListing = Loadable(
  lazy(() => import('../pages/applicantProgram/ApplicantProgramListing.tsx')),
);

const AddmissionIntek = Loadable(
  lazy(() => import('../pages/applicantProgram/AddmissionIntek.tsx')),
);
const Agentapplicationself = Loadable(
  lazy(() => import('../pages/agent/Agentapplicationself.tsx')),
);
const AgentappliRequest = Loadable(lazy(() => import('../pages/agent/AgentappliRequest.tsx')));
const Agentearning = Loadable(lazy(() => import('../pages/agent/Agentearning.tsx')));
const Agentfavorites = Loadable(lazy(() => import('../pages/agent/Agentfavorites.tsx')));
const AgentRating = Loadable(lazy(() => import('../pages/agent/AgentRating.tsx')));
const AgentunivAppli = Loadable(lazy(() => import('../pages/agent/AgentunivAppli.tsx')));
const Complaintagent = Loadable(lazy(() => import('../pages/agent/Complaintagent.tsx')));
const Tranferself = Loadable(lazy(() => import('../pages/agent/Tranferself.tsx')));
const Transactionagentlist = Loadable(
  lazy(() => import('../pages/agent/Transactionagentlist.tsx')),
);
const UserNotifi = Loadable(lazy(() => import('../pages/agent/UserNotifi.tsx')));

const AgentSignup = Loadable(lazy(() => import('../pages/agent/agentSignup/AgentSignup.tsx')));

const AgentProfile = Loadable(lazy(() => import('../pages/agent/agentProfile/AgentProfile.tsx')));

// *********** auth pages *****************
const Login = Loadable(lazy(() => import('../pages/authPages/Login.tsx')));
const Signup = Loadable(lazy(() => import('../pages/authPages/Signup.tsx')));

// -------------------------------------------------------------------------------------------------------
// <========================================> UNIVERSITY ROUTES END <=========================================>
// -------------------------------------------------------------------------------------------------------

/*****Routes******/

//////////////////////////////SMR
const TasksCommunicationsSupervisor = Loadable(
  lazy(() => import('../pages/GraduateModule/TasksCommunicationsSupervisor.tsx')),
);
const TaskCommStu = Loadable(lazy(() => import('../pages/GraduateModule/TaskCommStu.tsx')));
const Courseplan = Loadable(lazy(() => import('../pages/Course/Courseplan.tsx')));
const AddUni = Loadable(lazy(() => import('../pages/UniversityAdd/AddUni.tsx')));
const AddPrgm = Loadable(lazy(() => import('../pages/UniversityAdd/AddPrgm.tsx')));
const FormEditorOne = Loadable(lazy(() => import('../pages/Course/FormEditorOne')));
const TasksCommunicationsStudents = Loadable(
  lazy(() => import('../pages/GraduateModule/TasksCommunicationsStudents.tsx')),
);

///////////////RESHMA
const GradDefenceEvaluator = Loadable(
  lazy(() => import('../pages/GraduateModule/GradDefenceEvaluator.tsx')),
);
const AddPublications = Loadable(lazy(() => import('../pages/GraduateModule/AddPublications.tsx')));
const UniversityDetails = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/UniversityDetails.tsx')),
);
const UniversitiesFile = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/UniversitiesFile.tsx')),
);
const ManageCampusProgram = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageCampusProgram.tsx')),
);
const ManageCampus = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageCampus.tsx')),
);
const ManageCampusFaculity = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageCampusFaculity.tsx')),
);
const ManageFaculity = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageFaculity.tsx')),
);
const ManageProgram = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageProgram.tsx')),
);
const ProgramDetails = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ProgramDetails.tsx')),
);
const CampusDetails = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/CampusDetails.tsx')),
);
const ManageUniversities = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/ManageUniversities.tsx')),
);
//////////////////SHOBI
const AddResearcher = Loadable(lazy(() => import('../pages/GraduateModule/AddResearcher.tsx')));
const EditResearcher = Loadable(lazy(() => import('../pages/GraduateModule/EditResearcher.tsx')));
const Courses = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/Courses.tsx')),
);
const ManageCourseTypes = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ManageCourseTypes.tsx')),
);
const EditCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/EditCourse.tsx')),
);
const AddOfferedCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/AddOfferedCourse.tsx')),
);
const EditOfferedCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/EditOfferedCourse.tsx')),
);
const GradDefenses = Loadable(lazy(() => import('../pages/GraduateModule/GradDefenses.tsx')));
const EditDefense = Loadable(lazy(() => import('../pages/GraduateModule/EditDefense.tsx')));
const AddDefense = Loadable(lazy(() => import('../pages/GraduateModule/AddDefense.tsx')));
const ProgramLevels = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ProgramLevels.tsx')),
);
const ProgramModes = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ProgramModes.tsx')),
);
const Qualification = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/Qualification.tsx')),
);
const QualificationLevels = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/QualificationLevels.tsx')),
);
const StudentType = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/StudentType.tsx')),
);
const StudyType = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/StudyType.tsx')),
);
const UniActivityType = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/UniActivityType.tsx')),
);
const StdCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/StdCourse.tsx')),
);
const CourseReg = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/CourseReg.tsx')),
);
const ApplicationStatus = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/ApplicationStatus.tsx')),
);
const VerificationStatus = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/VerificationStatus.tsx')),
);
const Attachment = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/Attachment.tsx')),
);
const ContactTypes = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/ContactTypes.tsx')),
);
const Currencies = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/Currencies.tsx')),
);
const LanguageProfiency = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/LanguageProfiency.tsx')),
);
const CoreLanguage = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/CoreLanguage.tsx')),
);
const MaritalStatus = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/MaritalStatus.tsx')),
);
const PaymentModes = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/PaymentModes.tsx')),
);
const Relegions = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/Relegions.tsx')),
);
const Countries = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CoreData/Countries.tsx')),
);
const States = Loadable(lazy(() => import('../pages/AcademicManagementData/CoreData/States.tsx')));
const Cities = Loadable(lazy(() => import('../pages/AcademicManagementData/CoreData/Cities.tsx')));
const StdCourseReg = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/StdCourseReg.tsx')),
);
const ManageOfferedCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ManageOfferedCourse.tsx')),
);
const CourseByPlan = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/CourseByPlan.tsx')),
);
const ManageCoursePlan = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ManageCoursePlan.tsx')),
);
const ManageAcadamicSession = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/ManageAcadamicSession.tsx')),
);
/////////////////NANDHU
const Agentprofilev2 = Loadable(lazy(() => import('../pages/agent/Agentprofilev2.tsx')));
const Admincampus = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Admincampus.tsx')));
const Publications = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Publication.tsx')));

const Admincampusfaculty = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/Admincampusfaculty.tsx')),
);
const Adminfaculty = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Adminfaculty.tsx')));
const Adminuniversity = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/Adminuniversity.tsx')),
);
const Facultydetailss = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/Facultydetailss.tsx')),
);

const CampusFacultyDetails = Loadable(
  lazy(() => import('../pages/manageUniversityFolder/CampusFacultyDetails.tsx')),
);

const Addcampusprgm = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Addcampusprgm.tsx')));
const AddDefenseEvaluator = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/AddDefenseEvaluator.tsx')),
);
const EditDefenseEvaluator = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/EditDefenseEvaluator.tsx')),
);
const AddCourse = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageAcadamics/AddCourse.tsx')),
);

const Publication = Loadable(lazy(() => import('../pages/AdduniversityAdmin/Publication.tsx')));
const TasksStudents = Loadable(lazy(() => import('../pages/AdduniversityAdmin/TasksStudents.tsx')));
const Tasksupervisor = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/Tasksupervisor.tsx')),
);
const AddTasksupervisor = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/AddTasksupervisor.tsx')),
);
const EditTaskSupervisor = Loadable(
  lazy(() => import('../pages/AdduniversityAdmin/EditTaskSupervisor.tsx')),
);
//////////////////ASHIQ
const EditResearchActivities = Loadable(
  lazy(() => import('../pages/GraduateModule/EditResearchActivities.tsx')),
);
const ManageGradApplicationStatuses = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradApplicationStatuses.tsx')),
);
const ManageApplicationTypeChecklists = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageApplicationTypeChecklists.tsx')),
);
const GradeApplication1 = Loadable(
  lazy(() => import('../pages/GraduateModule/GradeApplication1.tsx')),
);
const ManageGradAuthorRole = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradAuthorRole.tsx')),
);
const ManageResearchActivities = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageResearchActivities.tsx')),
);
const ApplicationInterface2 = Loadable(
  lazy(() => import('../pages/GraduateModule/ApplicationInferface2.tsx')),
);
const AddResearchActivities = Loadable(
  lazy(() => import('../pages/GraduateModule/AddResearchActivities.tsx')),
);
const ManageModules = Loadable(lazy(() => import('../pages/menuManagement/ManageModules.tsx')));
const ManageModulePages = Loadable(
  lazy(() => import('../pages/menuManagement/ManageModulePages.tsx')),
);
const ManageGroupMenus = Loadable(
  lazy(() => import('../pages/menuManagement/ManageGroupMenus.tsx')),
);
const ManageGroupMenuLinks = Loadable(
  lazy(() => import('../pages/menuManagement/ManageGroupMenusLinks.tsx')),
);
const ManageUserInActiveMenu = Loadable(
  lazy(() => import('../pages/menuManagement/ManageUserInActiveMenu.tsx')),
);
const ManageUserInActiveLinks = Loadable(
  lazy(() => import('../pages/menuManagement/ManageUserInActiveLinks.tsx')),
);
const AddModulePage = Loadable(lazy(() => import('../pages/menuManagement/AddModulePage.tsx')));
const EditModulePage = Loadable(lazy(() => import('../pages/menuManagement/EditModulePage.tsx')));
const AddGroupMenuLinks = Loadable(
  lazy(() => import('../pages/menuManagement/AddGroupMenuLinks.tsx')),
);
const EditGroupMenuLinks = Loadable(
  lazy(() => import('../pages/menuManagement/EditGroupMenuLinks.tsx')),
);
const CourseMarksScheme = Loadable(
  lazy(() => import('../pages/AcademicManagementData/CourseMarksScheme.tsx')),
);
const ManageOfferedCourses = Loadable(
  lazy(() => import('../pages/AcademicManagementData/ManageOfferedCourses.tsx')),
);
const SummaryOfCurrentRegistrations = Loadable(
  lazy(() => import('../pages/AcademicManagementData/SummaryOfCurrentRegistrations.tsx')),
);
const EditPublication = Loadable(lazy(() => import('../pages/GraduateModule/EditPublication.tsx')));
const ManageGradApplicationTypes = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradApplicationTypes.tsx')),
);
const ManageGradDefenseStatuses = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradDefenseStatuses.tsx')),
);
const ManageGradDefenseTypes = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradDefenseTypes.tsx')),
);
const ManageDefenseVenues = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageDefenseVenues.tsx')),
);
const ManageGradEvaluationRatings = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradEvaluationRatings.tsx')),
);
const ManageGraduateIntakeStructures = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGraduateIntakeStructures.tsx')),
);
const ManageStructureMilestoneChecklists = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageStructureMilestoneChecklists.tsx')),
);
const GradPublicationTypes = Loadable(
  lazy(() => import('../pages/GraduateModule/GradPublicationTypes.tsx')),
);
const GradResearchAreas = Loadable(
  lazy(() => import('../pages/GraduateModule/GradResearchAreas.tsx')),
);
const GradResearchLanguages = Loadable(
  lazy(() => import('../pages/GraduateModule/GradResearchLanguages.tsx')),
);
const GradResearchStatus = Loadable(
  lazy(() => import('../pages/GraduateModule/GradResearchStatus.tsx')),
);
const ManageAgenttypess = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageAgenttypess.tsx')),
);
const ResearchesStructure = Loadable(
  lazy(() => import('../pages/GraduateModule/ResearchesStructure.tsx')),
);
const ManageAgentLevelrates = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageAgentLevelrates.tsx')),
);
const StructureMilestone = Loadable(
  lazy(() => import('../pages/GraduateModule/StructureMilestone.tsx')),
);
const SupervisorTypes = Loadable(lazy(() => import('../pages/GraduateModule/SupervisorTypes.tsx')));
const GradTemplates = Loadable(lazy(() => import('../pages/GraduateModule/GradTemplates.tsx')));
const GradResearchers = Loadable(lazy(() => import('../pages/GraduateModule/GradResearchers.tsx')));
const ManageResearches = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageResearches.tsx')),
);
const AddResearch = Loadable(lazy(() => import('../pages/GraduateModule/AddResearch.tsx')));
const EditResearch = Loadable(lazy(() => import('../pages/GraduateModule/EditResearch.tsx')));
//////////////////////THUSHARA
const UniversityManagementzzz = Loadable(
  lazy(() => import('../pages/ApplicationPro/applicantProgram/UniversityManagementzzz.tsx')),
);
const ProgramDataDetails = Loadable(
  lazy(() => import('../pages/ApplicationPro/applicantProgram/ProgramDataDetails.tsx')),
);

const AdimissionIntake = Loadable(
  lazy(() => import('../pages/ApplicationPro/applicantProgram/AddmissionIntek.tsx')),
);

const ApplicantProgramApp = Loadable(
  lazy(() => import('../pages/ApplicationPro/applicantProgram/ApplicantProgramApp.tsx')),
);
const ManageGradActivityTypes = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageGradActivityTypes.tsx')),
);
const Addgeneral = Loadable(lazy(() => import('../pages/ApplicantInfo/Addgeneral.tsx')));
const Attachmentadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Attachmentadd.tsx')));
const Contactinformation = Loadable(
  lazy(() => import('../pages/ApplicantInfo/Contactinformation.tsx')),
);
const Experienceadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Experienceadd.tsx')));
const Honorsadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Honorsadd.tsx')));
const Languageadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Languageadd.tsx')));
const MedicalInformation = Loadable(
  lazy(() => import('../pages/ApplicantInfo/MedicalInformation.tsx')),
);
const Qualificationsadd = Loadable(
  lazy(() => import('../pages/ApplicantInfo/Qualificationsadd.tsx')),
);
const Referencesadd = Loadable(lazy(() => import('../pages/ApplicantInfo/Referencesadd.tsx')));
const VisaInformationn = Loadable(
  lazy(() => import('../pages/ApplicantInfo/VisaInformationn.tsx')),
);
////New UserUniversity...////...///
const QAbanned = Loadable(lazy(() => import('../pages/Useruniversity/QAbanned.tsx')));
const Universityrestriction = Loadable(
  lazy(() => import('../pages/Useruniversity/Universityrestriction.tsx')),
);
const ManageApplicantsearch = Loadable(
  lazy(() => import('../pages/Useruniversity/ManageApplicantsearch.tsx')),
);
const ManageBanAgent = Loadable(lazy(() => import('../pages/Useruniversity/ManageBanAgent.tsx')));
const Listusers = Loadable(lazy(() => import('../pages/GraduateModule/Listusers.tsx')));
const ManageAgentApplicationss = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageAgentApplicationss.tsx')),
);
const ManageTransactionTypessss = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageTransactionTypessss.tsx')),
);
const UniversityRestrictionsss = Loadable(
  lazy(() => import('../pages/Useruniversity/UniversityRestrictionsss.tsx')),
);
const ManageTransferss = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageTransferss.tsx')),
);
const AddApplicationss = Loadable(
  lazy(() => import('../pages/GraduateModule/AddApplicationss.tsx')),
);
const ManageUserRatingsss = Loadable(
  lazy(() => import('../pages/Useruniversity/ManageUserRatingsss.tsx')),
);
const Manageuserss = Loadable(lazy(() => import('../pages/GraduateModule/Manageuserss.tsx')));
const AddUserss = Loadable(lazy(() => import('../pages/GraduateModule/AddUserss.tsx')));
const ManageAgenttwo = Loadable(lazy(() => import('../pages/GraduateModule/ManageAgenttwo.tsx')));
const ManageAgentLevelsss = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageAgentLevelsss.tsx')),
);
const ManageComplaintsss = Loadable(
  lazy(() => import('../pages/GraduateModule/ManageComplaintsss.tsx')),
);
// NEW ROUTES HERE..........
const University = Loadable(lazy(() => import('../pages/universityManagement/University.tsx')));
const AddUniversity = Loadable(
  lazy(() => import('../pages/universityManagement/AddUniversity.tsx')),
);

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      // { path: '/', name: 'Home', element: <Navigate to="" /> },
      {
        path: '/',
        name: 'Home',
        element: localStorage.getItem('logedIn') ? <Navigate to="/dashboards/minimal" /> : <Navigate to="/auth/login" />
      },
      { path: '/dashboards/minimal', name: 'Minimal', exact: true, element: <Minimal /> },
      { path: '/dashboards/analytical', name: 'Analytical', exact: true, element: <Analytical /> },
      {
        path: '/dashboards/demographical',
        name: 'Demographical',
        exact: true,
        element: <Demographical />,
      },
      { path: '/dashboards/modern', name: 'Modern', exact: true, element: <Modern /> },
      { path: '/apps/notes', name: 'notes', exact: true, element: <Notes /> },
      { path: '/apps/chat', name: 'chat', exact: true, element: <Chat /> },
      { path: '/apps/contacts', name: 'contacts', exact: true, element: <Contacts /> },
      { path: '/apps/calendar', name: 'calendar', exact: true, element: <Calendar /> },
      { path: '/apps/email', name: 'email', exact: true, element: <Email /> },
      { path: '/ecom/shop', name: 'email', exact: true, element: <Shop /> },
      { path: '/ecom/shopdetail', name: 'email', exact: true, element: <ShopDetail /> },
      { path: '/tickt/ticket-list', name: 'ticket list', exact: true, element: <TicketList /> },
      {
        path: '/tickt/ticket-detail',
        name: 'ticket detail',
        exact: true,
        element: <TicketDetail />,
      },
      { path: '/apps/treeview', name: 'email', exact: true, element: <Treeview /> },
      { path: '/ui/alerts', name: 'alerts', exact: true, element: <Alerts /> },
      { path: '/ui/badges', name: 'badges', exact: true, element: <Badges /> },
      { path: '/ui/buttons', name: 'buttons', exact: true, element: <Buttons /> },
      { path: '/ui/cards', name: 'cards', exact: true, element: <Cards /> },
      { path: '/ui/grid', name: 'grid', exact: true, element: <Grid /> },
      { path: '/ui/table', name: 'table', exact: true, element: <Tables /> },
      { path: '/ui/forms', name: 'forms', exact: true, element: <Forms /> },
      { path: '/ui/breadcrumbs', name: 'breadcrumbs', exact: true, element: <Breadcrumbs /> },
      { path: '/ui/dropdown', name: 'dropdown', exact: true, element: <Dropdowns /> },
      { path: '/ui/button-group', name: 'button group', exact: true, element: <BtnGroup /> },
      { path: '/ui/collapse', name: 'collapse', exact: true, element: <Collapse /> },
      { path: '/ui/list-group', name: 'list-group', exact: true, element: <ListGroup /> },
      { path: '/ui/modal', name: 'modal', exact: true, element: <Modal /> },
      { path: '/ui/navbar', name: 'navbar', exact: true, element: <Navbar /> },
      { path: '/ui/nav', name: 'nav', exact: true, element: <Nav /> },
      { path: '/ui/pagination', name: 'pagination', exact: true, element: <Pagination /> },
      { path: '/ui/popover', name: 'popover', exact: true, element: <Popover /> },
      { path: '/ui/progress', name: 'progress', exact: true, element: <Progress /> },
      { path: '/ui/spinner', name: 'spinner', exact: true, element: <Spinner /> },
      { path: '/ui/tabs', name: 'tabs', exact: true, element: <Tabs /> },
      { path: '/ui/toasts', name: 'toasts', exact: true, element: <Toasts /> },
      { path: '/ui/tooltip', name: 'tooltip', exact: true, element: <Tooltip /> },
      { path: '/form-layout/form-basic', name: 'form-basic', exact: true, element: <FormBasic /> },
      { path: '/form-layout/form-grid', name: 'form-grid', exact: true, element: <FormGrid /> },
      { path: '/form-layout/form-group', name: 'form-group', exact: true, element: <FormGroup /> },
      { path: '/form-layout/form-input', name: 'form-input', exact: true, element: <FormInput /> },
      {
        path: '/form-pickers/datepicker',
        name: 'datepicker',
        exact: true,
        element: <Datepicker />,
      },
      { path: '/form-pickers/tag-select', name: 'tag-select', exact: true, element: <TagSelect /> },
      { path: '/form-validation', name: 'form-validation', exact: true, element: <FormValidate /> },

      // ------------------->> UNIVERSITY PAGES ROUTE START <<----------------------------------
      { path: '/form-steps', name: 'form-steps', exact: true, element: <FormSteps /> },
      {
        path: '/applicantProfile',
        name: 'applicantProfile',
        exact: true,
        element: <ApplicantProfile />,
      },

      {
        path: '/form-application-steps',
        name: 'form-application-steps',
        exact: true,
        element: <FormStepsMain />,
      },

      {
        path: '/applicantProgramApplied',
        name: 'applicantProgramApplied',
        exact: true,
        element: <ApplicantProgramApplied />,
      },
      {
        path: '/applicantProgramListing',
        name: 'applicantProgramListing',
        exact: true,
        element: <ApplicantProgramListing />,
      },
      {
        path: '/addmissionintek',
        name: 'addmissionIntek',
        exact: true,
        element: <AddmissionIntek />,
      },
      {
        path: '/agentSignup',
        name: 'agentSignup',
        exact: true,
        element: <AgentSignup />,
      },

      {
        path: '/agentProfile',
        name: 'agentProfile',
        exact: true,
        element: <AgentProfile />,
      },

      {
        path: '/university',
        name: 'university',
        exact: true,
        element: <University />,
      },
      {
        path: '/add-university',
        name: 'addUniversity',
        exact: true,
        element: <AddUniversity />,
      },
      {
        path: '/ManageStructureMilestoneChecklists',
        name: 'ManageStructureMilestoneChecklists',
        exact: true,
        element: <ManageStructureMilestoneChecklists />,
      },

      {
        path: '/AddCourse',
        name: 'AddCourse',
        exact: true,
        element: <AddCourse />,
      },
      // ------------------->> UNIVERSITY PAGES ROUTE END <<----------------------------------

      { path: '/form-editor', name: 'form-editor', exact: true, element: <FormEditor /> },

      { path: '/tables/basic-table', name: 'basic-table', exact: true, element: <Basictable /> },
      {
        path: '/tables/react-table',
        name: 'react-table',
        exact: true,
        element: <CustomReactTable />,
      },
      {
        path: '/tables/data-table',
        name: 'data-table',
        exact: true,
        element: <ReactBootstrapTable />,
      },
      { path: '/charts/apex', name: 'apex', exact: true, element: <ApexCharts /> },
      { path: '/charts/chartjs', name: 'chartjs', exact: true, element: <ChartJs /> },
      { path: '/sample-pages/profile', name: 'profile', exact: true, element: <Profile /> },
      {
        path: '/sample-pages/helper-class',
        name: 'helper-class',
        exact: true,
        element: <HelperClass />,
      },
      {
        path: '/sample-pages/starterkit',
        name: 'starterkit',
        exact: true,
        element: <StarterKit />,
      },
      { path: '/sample-pages/gallery', name: 'gallery', exact: true, element: <Gallery /> },
      {
        path: '/sample-pages/search-result',
        name: 'search-result',
        exact: true,
        element: <SearchResult />,
      },
      { path: '/icons/bootstrap', name: 'bootstrap', exact: true, element: <Bootstrap /> },
      { path: '/icons/feather', name: 'feather', exact: true, element: <Feather /> },
      { path: '/map/vector', name: 'vector', exact: true, element: <CustomVectorMap /> },
      { path: '/widget', name: 'widget', exact: true, element: <Widget /> },
      { path: '/casl', name: 'casl', exact: true, element: <CASL /> },
      { path: '*', element: <Navigate to="/auth/404" /> },

      /////////////////////////SMR
      {
        path: '/form-application-steps',
        name: 'form-application-steps',
        exact: true,
        element: <FormStepsMain />,
      },
      {
        path: '/TasksCommunicationsStudents',
        name: 'TasksCommunicationsStudents',
        exact: true,
        element: <TasksCommunicationsStudents />,
      },
      {
        path: '/Courseplan',
        name: 'Courseplan',
        exact: true,
        element: <Courseplan />,
      },
      {
        path: 'AddUni',
        name: 'AddUni',
        exact: true,
        element: <AddUni />,
      },
      {
        path: 'AddPrgm',
        name: 'AddPrgm',
        exact: true,
        element: <AddPrgm />,
      },
      {
        path: 'FormEditorOne',
        name: 'FormEditorOne',
        exact: true,
        element: <FormEditorOne />,
      },
      {
        path: 'TasksCommunicationsSupervisor',
        name: 'TasksCommunicationsSupervisor',
        exact: true,
        element: <TasksCommunicationsSupervisor />,
      },

      /////////////////////RESHMA
      {
        path: '/GradResearchLanguages',
        name: 'GradResearchLanguages',
        exact: true,
        element: <GradResearchLanguages />,
      },
      {
        path: '/EditResearch',
        name: 'EditResearch',
        exact: true,
        element: <EditResearch />,
      },
      {
        path: '/AddResearch',
        name: 'AddResearch',
        exact: true,
        element: <AddResearch />,
      },

      {
        path: '/ManageResearches',
        name: 'ManageResearches',
        exact: true,
        element: <ManageResearches />,
      },
      {
        path: '/Addgeneral',
        name: 'Addgeneral',
        exact: true,
        element: <Addgeneral />,
      },
      {
        path: '/Attachmentadd',
        name: 'Attachmentadd',
        exact: true,
        element: <Attachmentadd />,
      },
      {
        path: '/ManageAgenttwo',
        name: 'ManageAgenttwo',
        exact: true,
        element: <ManageAgenttwo />,
      },
      {
        path: '/Contactinformation',
        name: 'Contactinformation',
        exact: true,
        element: <Contactinformation />,
      },
      {
        path: '/Experienceadd',
        name: 'Experienceadd',
        exact: true,
        element: <Experienceadd />,
      },
      {
        path: '/Honorsadd',
        name: 'Honorsadd',
        exact: true,
        element: <Honorsadd />,
      },
      {
        path: '/QAbanned',
        name: 'QAbanned',
        exact: true,
        element: <QAbanned />,
      },
      {
        path: '/Manageuserss',
        name: 'Manageuserss',
        exact: true,
        element: <Manageuserss />,
      },
      {
        path: '/ManageBanAgent',
        name: 'ManageBanAgent',
        exact: true,
        element: <ManageBanAgent />,
      },
      {
        path: '/Listusers',
        name: 'Listusers',
        exact: true,
        element: <Listusers />,
      },
      {
        path: '/Universityrestriction',
        name: 'Universityrestriction',
        exact: true,
        element: <Universityrestriction />,
      },
      {
        path: '/ManageApplicantsearch',
        name: 'ManageApplicantsearch',
        exact: true,
        element: <ManageApplicantsearch />,
      },
      {
        path: '/Agentprofilev2',
        name: 'Agentprofilev2',
        exact: true,
        element: <Agentprofilev2 />,
      },
      {
        path: '/Languageadd',
        name: 'Languageadd',
        exact: true,
        element: <Languageadd />,
      },
      {
        path: '/MedicalInformation',
        name: 'MedicalInformation',
        exact: true,
        element: <MedicalInformation />,
      },
      {
        path: '/Qualificationsadd',
        name: 'Qualificationsadd',
        exact: true,
        element: <Qualificationsadd />,
      },
      {
        path: '/Referencesadd',
        name: 'Referencesadd',
        exact: true,
        element: <Referencesadd />,
      },
      {
        path: '/VisaInformationn',
        name: 'VisaInformationn',
        exact: true,
        element: <VisaInformationn />,
      },
      {
        path: '/GradTemplates',
        name: 'GradTemplates',
        exact: true,
        element: <GradTemplates />,
      },
      {
        path: '/StructureMilestone',
        name: 'StructureMilestone',
        exact: true,
        element: <StructureMilestone />,
      },
      {
        path: '/ManageAgentLevelrates',
        name: 'ManageAgentLevelrates',
        exact: true,
        element: <ManageAgentLevelrates />,
      },
      {
        path: '/ManageTransferss',
        name: 'ManageTransferss',
        exact: true,
        element: <ManageTransferss />,
      },
      {
        path: '/GradDefenceEvaluator',
        name: 'GradDefenceEvaluator',
        exact: true,
        element: <GradDefenceEvaluator />,
      },
      {
        path: '/ManageGradApplicationTypes',
        name: 'ManageGradApplicationTypes',
        exact: true,
        element: <ManageGradApplicationTypes />,
      },
      {
        path: '/AddUserss',
        name: 'AddUserss',
        exact: true,
        element: <AddUserss />,
      },
      {
        path: '/ManageGradActivityTypes',
        name: 'ManageGradActivityTypes',
        exact: true,
        element: <ManageGradActivityTypes />,
      },
      {
        path: '/GradResearchStatus',
        name: 'GradResearchStatus',
        exact: true,
        element: <GradResearchStatus />,
      },
      {
        path: '/AddPublications',
        name: 'AddPublications',
        exact: true,
        element: <AddPublications />,
      },

      {
        path: '/Publications',
        name: 'Publications',
        exact: true,
        element: <Publications />,
      },
      {
        path: '/AddApplicationss',
        name: 'AddApplicationss',
        exact: true,
        element: <AddApplicationss />,
      },
      {
        path: '/UniversityDetails',
        name: 'UniversityDetails',
        exact: true,
        element: <UniversityDetails />,
      },
      {
        path: '/ManageTransactionTypessss',
        name: 'ManageTransactionTypessss',
        exact: true,
        element: <ManageTransactionTypessss />,
      },
      {
        path: '/GradResearchAreas',
        name: 'GradResearchAreas',
        exact: true,
        element: <GradResearchAreas />,
      },
      {
        path: '/SupervisorTypes',
        name: 'SupervisorTypes',
        exact: true,
        element: <SupervisorTypes />,
      },
      {
        path: '/ManageAgenttypess',
        name: 'ManageAgenttypess',
        exact: true,
        element: <ManageAgenttypess />,
      },
      {
        path: '/Agentapplicationself',
        name: 'Agentapplicationself',
        exact: true,
        element: <Agentapplicationself />,
      },
      {
        path: '/AgentappliRequest',
        name: 'AgentappliRequest',
        exact: true,
        element: <AgentappliRequest />,
      },
      {
        path: '/Agentearning',
        name: 'Agentearning',
        exact: true,
        element: <Agentearning />,
      },
      {
        path: '/UniversityRestrictionsss',
        name: 'UniversityRestrictionsss',
        exact: true,
        element: <UniversityRestrictionsss />,
      },
      {
        path: '/Agentfavorites',
        name: 'Agentfavorites',
        exact: true,
        element: <Agentfavorites />,
      },
      {
        path: '/AgentRating',
        name: 'AgentRating',
        exact: true,
        element: <AgentRating />,
      },
      {
        path: '/ManageUserRatingsss',
        name: 'ManageUserRatingsss',
        exact: true,
        element: <ManageUserRatingsss />,
      },
      {
        path: '/AgentunivAppli',
        name: 'AgentunivAppli',
        exact: true,
        element: <AgentunivAppli />,
      },
      {
        path: '/Complaintagent',
        name: 'Complaintagent',
        exact: true,
        element: <Complaintagent />,
      },
      {
        path: '/Tranferself',
        name: 'Tranferself',
        exact: true,
        element: <Tranferself />,
      },
      {
        path: '/Transactionagentlist',
        name: 'Transactionagentlist',
        exact: true,
        element: <Transactionagentlist />,
      },
      {
        path: '/UserNotifi',
        name: 'UserNotifi',
        exact: true,
        element: <UserNotifi />,
      },
      {
        path: '/ManageCampusProgram',
        name: 'ManageCampusProgram',
        exact: true,
        element: <ManageCampusProgram />,
      },
      {
        path: '/ApplicationInterface2',
        name: 'ApplicationInterface2',
        exact: true,
        element: <ApplicationInterface2 />,
      },
      {
        path: '/ManageUniversities',
        name: 'ManageUniversities',
        exact: true,
        element: <ManageUniversities />,
      },
      {
        path: '/GradResearchers',
        name: 'GradResearchers',
        exact: true,
        element: <GradResearchers />,
      },
      {
        path: '/EditPublication',
        name: 'EditPublication',
        exact: true,
        element: <EditPublication />,
      },
      {
        path: '/GradeApplication1',
        name: 'GradeApplication1',
        exact: true,
        element: <GradeApplication1 />,
      },
      {
        path: '/dashboards/UniversitiesFile',
        name: 'UniversitiesFile',
        exact: true,
        element: <UniversitiesFile />,
      },

      {
        path: '/ManageCampus',
        name: 'ManageCampus',
        exact: true,
        element: <ManageCampus />,
      },
      {
        path: '/ManageCampusFaculity',
        name: 'ManageCampusFaculity',
        exact: true,
        element: <ManageCampusFaculity />,
      },
      {
        path: '/ManageFaculity',
        name: 'ManageFaculity',
        exact: true,
        element: <ManageFaculity />,
      },
      {
        path: '/ResearchesStructure',
        name: 'ResearchesStructure',
        exact: true,
        element: <ResearchesStructure />,
      },
      {
        path: '/ProgramDetails',
        name: 'ProgramDetails',
        exact: true,
        element: <ProgramDetails />,
      },
      {
        path: '/ManageProgram',
        name: 'ManageProgram',
        exact: true,
        element: <ManageProgram />,
      },

      {
        path: '/CampusDetails',
        name: 'CampusDetails',
        exact: true,
        element: <CampusDetails />,
      },
      {
        path: '/ManageGradDefenseTypes',
        name: 'ManageGradDefenseTypes',
        exact: true,
        element: <ManageGradDefenseTypes />,
      },
      {
        path: '/ManageDefenseVenues',
        name: 'ManageDefenseVenues',
        exact: true,
        element: <ManageDefenseVenues />,
      },
      {
        path: '/ManageGradEvaluationRatings',
        name: 'ManageGradEvaluationRatings',
        exact: true,
        element: <ManageGradEvaluationRatings />,
      },
      {
        path: '/ManageGraduateIntakeStructures',
        name: 'ManageGraduateIntakeStructures',
        exact: true,
        element: <ManageGraduateIntakeStructures />,
      },

      /////////////////NANDHU
      {
        path: '/ManageAgentLevelsss',
        name: 'ManageAgentLevelsss',
        exact: true,
        element: <ManageAgentLevelsss />,
      },
      {
        path: '/Addcampusprgm',
        name: 'Addcampusprgm',
        exact: true,
        element: <Addcampusprgm />,
      },
      {
        path: '/Admincampusfaculty',
        name: 'Admincampusfaculty',
        exact: true,
        element: <Admincampusfaculty />,
      },
      {
        path: '/ManageComplaintsss',
        name: 'ManageComplaintsss',
        exact: true,
        element: <ManageComplaintsss />,
      },
      {
        path: '/Facultydetailss',
        name: 'Facultydetailss',
        exact: true,
        element: <Facultydetailss />,
      },
      {
        path: '/CampusFacultyDetails/:id',
        name: 'CampusFacultyDetails',
        exact: true,
        element: <CampusFacultyDetails />,
      },
      // {
      //   path: '/CampusFacultyDetails',
      //   name: 'CampusFacultyDetails',
      //   exact: true,
      //   element: <CampusFacultyDetails />,
      // },
      {
        path: '/ManageAgentApplicationss',
        name: 'ManageAgentApplicationss',
        exact: true,
        element: <ManageAgentApplicationss />,
      },
      {
        path: '/Adminuniversity',
        name: 'Adminuniversity',
        exact: true,
        element: <Adminuniversity />,
      },
      {
        path: '/Adminfaculty',
        name: 'Adminfaculty',
        exact: true,
        element: <Adminfaculty />,
      },
      {
        path: '/Admincampus',
        name: 'Admincampus',
        exact: true,
        element: <Admincampus />,
      },
      {
        path: '/GradPublicationTypes',
        name: 'GradPublicationTypes',
        exact: true,
        element: <GradPublicationTypes />,
      },
      {
        path: '/AddDefenseEvaluator',
        name: 'AddDefenseEvaluator',
        exact: true,
        element: <AddDefenseEvaluator />,
      },
      {
        path: '/EditDefenseEvaluator',
        name: 'EditDefenseEvaluator',
        exact: true,
        element: <EditDefenseEvaluator />,
      },
      {
        path: '/Publication',
        name: 'Publication',
        exact: true,
        element: <Publication />,
      },
      {
        path: '/TasksStudents',
        name: 'TasksStudents',
        exact: true,
        element: <TasksStudents />,
      },
      {
        path: '/Tasksupervisor',
        name: 'Tasksupervisor',
        exact: true,
        element: <Tasksupervisor />,
      },
      {
        path: '/AddTasksupervisor',
        name: 'AddTasksupervisor',
        exact: true,
        element: <AddTasksupervisor />,
      },

      {
        path: '/EditTaskSupervisor',
        name: 'EditTaskSupervisor',
        exact: true,
        element: <EditTaskSupervisor />,
      },
      ///////////////////SHOBI
      {
        path: '/AddResearcher',
        name: 'AddResearcher',
        exact: true,
        element: <AddResearcher />,
      },
      {
        path: '/EditResearcher',
        name: 'EditResearcher',
        exact: true,
        element: <EditResearcher />,
      },
      {
        path: '/Courses',
        name: 'Courses',
        exact: true,
        element: <Courses />,
      },
      {
        path: '/ManageCourseTypes',
        name: 'ManageCourseTypes',
        exact: true,
        element: <ManageCourseTypes />,
      },
      {
        path: '/EditCourse',
        name: 'EditCourse',
        exact: true,
        element: <EditCourse />,
      },
      {
        path: '/AddOfferedCourse',
        name: 'AddOfferedCourse',
        exact: true,
        element: <AddOfferedCourse />,
      },
      {
        path: '/EditOfferedCourse',
        name: 'EditOfferedCourse',
        exact: true,
        element: <EditOfferedCourse />,
      },
      {
        path: '/GradDefenses',
        name: 'GradDefenses',
        exact: true,
        element: <GradDefenses />,
      },
      {
        path: '/AddDefense',
        name: 'AddDefense',
        exact: true,
        element: <AddDefense />,
      },
      {
        path: '/EditDefense',
        name: 'EditDefense',
        exact: true,
        element: <EditDefense />,
      },

      {
        path: 'ProgramLevels',
        name: 'ProgramLevels',
        exact: true,
        element: <ProgramLevels />,
      },
      {
        path: '/ProgramModes',
        name: 'ProgramModes',
        exact: true,
        element: <ProgramModes />,
      },
      {
        path: '/Qualification',
        name: 'Qualification',
        exact: true,
        element: <Qualification />,
      },
      {
        path: '/QualificationLevels',
        name: 'QualificationLevels',
        exact: true,
        element: <QualificationLevels />,
      },
      {
        path: '/StudentType',
        name: 'StudentType',
        exact: true,
        element: <StudentType />,
      },
      {
        path: '/StudyType',
        name: 'StudyType',
        exact: true,
        element: <StudyType />,
      },
      {
        path: '/UniActivityType',
        name: 'UniActivityType',
        exact: true,
        element: <UniActivityType />,
      },
      {
        path: '/StdCourse',
        name: 'StdCourse',
        exact: true,
        element: <StdCourse />,
      },
      {
        path: '/CourseReg',
        name: 'CourseReg',
        exact: true,
        element: <CourseReg />,
      },
      {
        path: '/StdCourseReg',
        name: 'StdCourseReg',
        exact: true,
        element: <StdCourseReg />,
      },
      {
        path: '/ApplicationStatus',
        name: 'ApplicationStatus',
        exact: true,
        element: <ApplicationStatus />,
      },
      {
        path: '/VerificationStatus',
        name: 'VerificationStatus',
        exact: true,
        element: <VerificationStatus />,
      },
      {
        path: '/Attachment',
        name: 'Attachment',
        exact: true,
        element: <Attachment />,
      },
      {
        path: '/ContactTypes',
        name: 'ContactTypes',
        exact: true,
        element: <ContactTypes />,
      },
      {
        path: '/Currencies',
        name: 'Currencies',
        exact: true,
        element: <Currencies />,
      },
      {
        path: '/LanguageProfiency',
        name: 'LanguageProfiency',
        exact: true,
        element: <LanguageProfiency />,
      },
      {
        path: '/CoreLanguage',
        name: 'CoreLanguage',
        exact: true,
        element: <CoreLanguage />,
      },
      {
        path: '/MaritalStatus',
        name: 'MaritalStatus',
        exact: true,
        element: <MaritalStatus />,
      },
      {
        path: '/PaymentModes',
        name: 'PaymentModes',
        exact: true,
        element: <PaymentModes />,
      },
      {
        path: '/Relegions',
        name: 'Relegions',
        exact: true,
        element: <Relegions />,
      },
      {
        path: '/Countries',
        name: 'Countries',
        exact: true,
        element: <Countries />,
      },
      {
        path: '/States',
        name: 'States',
        exact: true,
        element: <States />,
      },
      {
        path: '/Cities',
        name: 'Cities',
        exact: true,
        element: <Cities />,
      },
      {
        path: '/ManageOfferedCourse',
        name: 'ManageOfferedCourse',
        exact: true,
        element: <ManageOfferedCourse />,
      },
      {
        path: '/CourseByPlan',
        name: 'CourseByPlan',
        exact: true,
        element: <CourseByPlan />,
      },
      {
        path: '/ManageCoursePlan',
        name: 'ManageCoursePlan',
        exact: true,
        element: <ManageCoursePlan />,
      },
      {
        path: '/ManageAcadamicSession',
        name: 'ManageAcadamicSession',
        exact: true,
        element: <ManageAcadamicSession />,
      },
      /////////////////ASHIQ
      {
        path: '/EditResearchActivities',
        name: 'EditResearchActivities',
        exact: true,
        element: <EditResearchActivities />,
      },
      {
        path: '/ManageGradApplicationStatuses',
        name: 'ManageGradApplicationStatuses',
        exact: true,
        element: <ManageGradApplicationStatuses />,
      },
      {
        path: '/ManageApplicationTypeChecklists',
        name: 'ManageApplicationTypeChecklists',
        exact: true,
        element: <ManageApplicationTypeChecklists />,
      },
      {
        path: '/ManageResearchActivities',
        name: 'ManageResearchActivities',
        exact: true,
        element: <ManageResearchActivities />,
      },
      {
        path: '/AddResearchActivities',
        name: 'AddResearchActivities',
        exact: true,
        element: <AddResearchActivities />,
      },

      {
        path: '/ManageModules',
        name: 'ManageModules',
        exact: true,
        element: <ManageModules />,
      },
      {
        path: '/ManageModulePages',
        name: 'ManageModulePages',
        exact: true,
        element: <ManageModulePages />,
      },
      {
        path: '/ManageGroupMenus',
        name: 'ManageGroupMenus',
        exact: true,
        element: <ManageGroupMenus />,
      },
      {
        path: '/ManageGroupMenuLinks',
        name: 'ManageGroupMenuLinks',
        exact: true,
        element: <ManageGroupMenuLinks />,
      },
      {
        path: '/ManageUserInActiveMenu',
        name: 'ManageUserInActiveMenu',
        exact: true,
        element: <ManageUserInActiveMenu />,
      },
      {
        path: '/ManageUserInActiveLinks',
        name: 'ManageUserInActiveLinks',
        exact: true,
        element: <ManageUserInActiveLinks />,
      },
      {
        path: '/AddModulePage',
        name: 'AddModulePage',
        exact: true,
        element: <AddModulePage />,
      },
      {
        path: '/EditModulePage',
        name: 'EditModulePage',
        exact: true,
        element: <EditModulePage />,
      },
      {
        path: '/AddGroupMenuLinks',
        name: 'AddGroupMenuLinks',
        exact: true,
        element: <AddGroupMenuLinks />,
      },
      {
        path: '/EditGroupMenuLinks',
        name: 'EditGroupMenuLinks',
        exact: true,
        element: <EditGroupMenuLinks />,
      },
      {
        path: '/CourseMarksScheme',
        name: 'CourseMarksScheme',
        exact: true,
        element: <CourseMarksScheme />,
      },
      {
        path: '/ManageOfferedCourses',
        name: 'ManageOfferedCourses',
        exact: true,
        element: <ManageOfferedCourses />,
      },
      {
        path: '/SummaryOfCurrentRegistrations',
        name: 'SummaryOfCurrentRegistrations',
        exact: true,
        element: <SummaryOfCurrentRegistrations />,
      },
      {
        path: '/ManageGradAuthorRole',
        name: 'ManageGradAuthorRole',
        exact: true,
        element: <ManageGradAuthorRole />,
      },
      {
        path: '/ManageGradDefenseStatuses',
        name: 'ManageGradDefenseStatuses',
        exact: true,
        element: <ManageGradDefenseStatuses />,
      },
      ////////////////THUSHAR
      {
        path: '/UniversityManagementzzz',
        name: 'UniversityManagementzzz',
        exact: true,
        element: <UniversityManagementzzz />,
      },
      {
        path: '/ProgramDataDetails',
        name: 'ProgramDataDetails',
        exact: true,
        element: <ProgramDataDetails />,
      },
      {
        path: '/ManageApplicationTypeChecklists',
        name: 'ManageApplicationTypeChecklists',
        exact: true,
        element: <ManageApplicationTypeChecklists />,
      },
      {
        path: '/applicantProgramApp',
        name: 'applicantProgramApp',
        exact: true,
        element: <ApplicantProgramApp />,
      },

      {
        path: '/AdimissionIntake',
        name: 'AdimissionIntake',
        exact: true,
        element: <AdimissionIntake />,
      },
    ],
  },

  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },

      { path: 'login', element: localStorage.getItem('logedIn') ? <Navigate to="/dashboards/minimal" /> : <Login /> },
      { path: 'signup', element: localStorage.getItem('logedIn') ? <Navigate to="/dashboards/minimal" /> : <Signup /> },

      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'loginformik', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
];

export default ThemeRoutes;
